import { adminClientMessage, AdminResErrorType } from '../../../config/adminRequest';
import { Button, Form, Modal, Spin, Tabs } from 'antd';
import React, { RefObject, useEffect, useState } from 'react';
import { FormInstance } from 'antd/lib/form';
import { FeeSetting } from '../../domain/usdt-providers/systemProvider';
import CoreDrawer from '../../core/components/CoreDrawer';
import CreditSettingFrom from '../../domain/components/setttings/CreditSettingFrom';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { useOperatorLogger } from '../../core/hooks/useOperatorLogger';
import DepositSettingProvider, { DepositSetting } from '../../domain/usdt-providers/depositSettingProvider';
import * as _ from 'lodash';
import DepositTokenSettingForm from '../../domain/components/setttings/DepositTokenSettingForm';
import DepositModeUrlFrom from '../../domain/components/setttings/DepositModeUrlFrom';
import DepositExpireSettingFrom from '../../domain/components/setttings/DepositExpireSettingFrom';

export enum SystemSettingActionType {
  AddAgent,
  AddNormal,
  Extend,
  Edit,
}

const fromEditErrorModel = (
  err: AdminResErrorType,
  state: { type: string },
) => {
  Modal.error({
    title: state.type,
    content: (
      <div>
        <ul>
          <li>原因: {adminClientMessage.get(err.clientCode) ?? err.message}</li>
          {err.clientCode === 'feeRate.check.error' ? (
            <>
              <li>欄位: {err.data.colName}</li>
              <li>最小值: {err.data.min}</li>
              <li>最大值: {err.data.max}</li>
            </>
          ) : (
            <></>
          )}
        </ul>
      </div>
    ),
    okText: '確認',
  });
};

const initDepositSettingData = {
  systemId: '',
  depositMode: 4,
  notifyChannelFlag: true,
  notifyTokenFlag: false,
  notifyUrl: '',
  notifyFailUrl: '',
  notifySuccessUrl: '',
  changeOrderEnable: false,
  creditOrderDomain: '',
  depositNotifyChannelFlag: true,
  depositExpireDefaultTime: 30,
  autoMatchPolling: false,
  tokenSetting: {
    ETHEREUM: {
      USDT: {
        active: true,
        creditFeeRate: 0,
        innerDepositRate: 0,
        creditAmountPrecision: 3,
        creditMinFee: 0,
      },
      ETH: {
        active: true,
        creditFeeRate: 0,
        innerDepositRate: 0,
        creditAmountPrecision: 3,
        creditMinFee: 0,
      },
    },
    TRON: {
      USDT: {
        active: true,
        creditFeeRate: 0,
        innerDepositRate: 0,
        creditAmountPrecision: 3,
        creditMinFee: 0,
      },
    },
    SOLANA: {
      USDT: {
        active: true,
        creditFeeRate: 0,
        innerDepositRate: 0,
        creditAmountPrecision: 3,
        creditMinFee: 0,
      },
    },
  },
};

enum ActionType {
  Closed,
}

const formRef: RefObject<FormInstance> = React.createRef<FormInstance>();

const DepositSettingForm = (props: {
  type: SystemSettingActionType;
  visible: boolean;
  closeForm: () => void;
  systemId: string;
}) => {
  let keyNumber = 2;
  const [feeRange, setfeeRange] = useState<FeeSetting>({
    ETHEREUM: {
      USDT: {
        max: 0,
        min: 0,
      },
      ETH: {
        max: 0,
        min: 0,
      },
    },
    TRON: {
      USDT: {
        max: 0,
        min: 0,
      },
      TRX: {
        max: 0,
        min: 0,
      },
    },
    SOLANA: {
      USDT: {
        max: 0,
        min: 0,
      },
    },
    BSC: {
      BUSD: {
        max: 0,
        min: 0,
      },
      USDT: {
        max: 0,
        min: 0,
      },
      USDC: {
        max: 0,
        min: 0,
      }
    },
  });

  const [actionState] = useState(new Subject<ActionType>());
  useEffect(() => {
    actionState.pipe(filter((x) => x === ActionType.Closed)).subscribe((x) => {
      props.closeForm();
    });
    // eslint-disable-next-line
  }, [actionState]);
  const [actionType, setActionType] = useState<SystemSettingActionType>(
    props.type,
  );
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DepositSetting>(initDepositSettingData);
  useEffect(() => {
    if (props.systemId) {
      DepositSettingProvider.getFeeRateRange(props.systemId).then((data) => {
        setfeeRange(data);
      });
    }
  }, [props.systemId, props.visible]);

  const opLogger = useOperatorLogger();

  const successHandle = () => {
    if (formRef.current) formRef.current.resetFields();
    actionState.next(ActionType.Closed);
  };

  const formSave = () => {
    if (formRef.current) {
      switch (actionType) {
        case SystemSettingActionType.Edit:
          if (props.systemId)
            formRef.current.validateFields().then((value) => {


              for (const channel in data.tokenSetting) {
                const tokenList = data.tokenSetting[channel];
                for (const token in tokenList) {
                  data.tokenSetting[channel][token].creditFeeRate = value[`${channel}_${token}_creditFeeRate`] ??
                    data.tokenSetting[channel][token].creditFeeRate;
                  data.tokenSetting[channel][token].innerDepositRate = value[`${channel}_${token}_innerDepositRate`] ??
                    data.tokenSetting[channel][token].innerDepositRate;
                  data.tokenSetting[channel][token].creditAmountPrecision = value[`${channel}_${token}_creditAmountPrecision`] ??
                    data.tokenSetting[channel][token].creditAmountPrecision;
                  data.tokenSetting[channel][token].creditMinFee = value[`${channel}_${token}_creditMinFee`] ??
                    data.tokenSetting[channel][token].creditMinFee;
                }
              }

              const d = JSON.parse(JSON.stringify(data));
              const v = JSON.parse(JSON.stringify(value));
              const req = { ...d, ...v };

              DepositSettingProvider.updateSetting(
                { ...d, ...v },
                // metaData.curSystemId!,
              ).then(
                (rss) => {
                  Modal.success({
                    content: '修改成功',
                    okText: '確認',
                    onOk: () => successHandle(),
                  });
                  opLogger.log({
                    action: '修改',
                    payload: {
                      req: req,
                      res: rss,
                    },
                    systemId: props.systemId,
                  });
                },
                (err) => {
                  fromEditErrorModel(err as any, { type: '更新失敗' });
                  opLogger.log({
                    action: '修改',
                    payload: {
                      req: req,
                      res: err,
                    },
                    systemId: props.systemId,
                  });
                },
              );
            });
          break;
      }
    }
  };
  useEffect(() => {
    setActionType(props.type);
  }, [props.type]);

  useEffect(() => {
    if (props.systemId && props.visible) {
      setLoading(true);
      DepositSettingProvider.getSetting(props.systemId)
        .then((data) => {
          formRef.current?.setFieldsValue({
            notifyChannelFlag: data.notifyChannelFlag,
            notifyTokenFlag: data.notifyTokenFlag,
            depositMode: data.depositMode,
            notifySuccessUrl: data.notifySuccessUrl,
            notifyFailUrl: data.notifyFailUrl,
            creditOrderDomain: data.creditOrderDomain,
            depositExpireDefaultTime: data.depositExpireDefaultTime,
            autoMatchPolling: data.autoMatchPolling,
          });
          setData(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [props.systemId, actionType, props.visible]);

  return (
    <>
      <CoreDrawer
        title={'編輯充值設定'}
        width={640}
        visible={props.visible}
        onClose={() => {
          actionState.next(ActionType.Closed);
        }}
        destroyOnClose={true}
        footerbuttons={[
          <Button key={1} onClick={formSave}>
            送出
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          <Form
            ref={formRef}
            layout='vertical'
            name='systemSettingForm'
            initialValues={data}
          >
            <Tabs type='card'>
              <Tabs.TabPane key={1} tab='基本設定'>
                <CreditSettingFrom
                  notifyChannelFlag={data.notifyChannelFlag}
                  notifyTokenFlag={data.notifyTokenFlag}
                />
                <DepositModeUrlFrom
                  depositMode={data.depositMode}
                  actionType={1}
                />
                <DepositExpireSettingFrom
                  depositExpireDefaultTime={data.depositMode}
                  actionType={1}
                />
              </Tabs.TabPane>
              {_.map(data.tokenSetting, (channelObj, channel) => {
                let isTokenActive = false;
                _.map(channelObj, (tokenObj, token) => {
                  if (tokenObj.active) {
                    isTokenActive = true;
                  }
                });
                if (isTokenActive) {
                  keyNumber++;
                  return (
                    <Tabs.TabPane key={keyNumber} tab={channel}>
                      <DepositTokenSettingForm
                        feeRange={feeRange}
                        tokenSetting={data.tokenSetting}
                        channel={channel}
                        data={channelObj}
                      />
                    </Tabs.TabPane>
                  );
                }
              })}
            </Tabs>
          </Form>
        </Spin>
      </CoreDrawer>
    </>
  );
};

export default DepositSettingForm;
