import { UsdtUri } from '../../core/configs/services';
import { AdminResponse } from '../models/adminResponse';
import adminRequest from '../../../config/adminRequest';

export type TokenRowType = {
  channel: string,
  token: string,
  transferInfo: string,
  feeRate: number,
  minimumAmount: number
}

export type TokenSetting = {
  [channel: string]: {
    [token: string]: TokenSettingType;
  };
}

export type NumberComponentType = {
  [id:string]:  number,
}


export type TokenSettingType = {
  active?: boolean,
  feeRate: number,
  withdrawFee: number,
  withdrawOption: string,
  minimumAmount: number
}

export interface WithdrawSetting {
  systemId: string;
  tokenSetting: TokenSetting;
  networkFeeSetting: NetworkSetting;
}

export interface FeeSetting {
  ETHEREUM: {
    USDT: {
      max: number,
      min: number,
    },
    ETH: {
      max: number,
      min: number,
    },
  },
  TRON: {
    USDT: {
      max: number,
      min: number,
    },
    TRX: {
      max: number,
      min: number,
    },
  },
  SOLANA: {
    USDT: {
      max: number,
      min: number,
    },
  },
  BSC: {
    BUSD: {
      max: number,
      min: number,
    },
    USDT: {
      max: number,
      min: number,
    },
    USDC: {
      max: number,
      min: number,
    },
  },
}

export type NetworkSetting = {
  [channel: string]: {
    [token: string]: {
      transferMode: string,
      staticFee?: number
    }
  };
}

export default class WithdrawSettingProvider {

  static async getSetting(systemId: string) {
    const result = await adminRequest.get<AdminResponse<WithdrawSetting>>(
      UsdtUri(`admin/withdraw/setting/${systemId}`),
      {
        headers: {
          SYSTEM_ID: systemId,
        },
      },
    );
    return result.data.data;
  }

  static async updateSetting(data: WithdrawSetting) {
    const result = await adminRequest.put<AdminResponse<WithdrawSetting>>(
      UsdtUri(`admin/withdraw/setting/`),
      data,
    );
    return result.data.data;
  }

  static async getFeeRateRange(
    systemId?: string,
  ): Promise<FeeSetting> {
    const res = await adminRequest.get<AdminResponse<any>>(UsdtUri(`admin/withdraw/setting/fee-rate-range/${systemId}`), {
      params: { systemId },
    });
    return res.data.data;
  }

}
